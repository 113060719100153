export default class {
  constructor() {
    this.instance = [
      {
        header: "ACADÊMICO",
        icon: "graduation-cap",
        rule: "isLogged",
        submenu: [
          {
            index: "1.1",
            icon: "medal",
            url: "/academico/historico-escolar",
            title: "Histórico Escolar",
            rule: "",
          },
          {
            index: "1.2",
            icon: "clock",
            url: "/academico/quadro-de-horario",
            title: "Quadro de Horário",
            rule: "",
          },
          {
            index: "1.3",
            icon: "calendar",
            url: "/academico/agendamento-de-prova",
            title: "Agendamento de Prova",
            rule: "",
          },
          {
            index: "1.4",
            icon: "archive",
            url: "/academico/documentos-academicos",
            title: "Documentos Acadêmicos",
            rule: "",
          },
          {
            index: "1.5",
            icon: "window-maximize",
            url: "/academico/atividade-complementar",
            title: "Atividade Complementar",
            rule: "",
          },
          {
            index: "1.6",
            icon: "window-maximize",
            url: "/academico/manuais-regulamentos",
            title: "Manuais e regulamentos",
            rule: "",
          },
          /*{
                            index: "1.6",
                            icon: "file-invoice",
                            url: "https://qi.edu.br/wp-content/uploads/2025/02/REGULAMENTO-PROGRAMA-DE-MONITORIA.docx.pdf",
                            title: "Regulamento de Monitoria",
                            rule: "",
                            target: "_blank"
                        },*/
          {
            index: "1.7",
            icon: "stamp",
            url: "/academico/meus-certificados",
            title: "Meus Certificados",
            rule: "",
          },
        ],
      },
      {
        header: "FINANCEIRO",
        icon: "coins",
        rule: "isLogged",
        submenu: [
          {
            index: "2.1",
            icon: "file-contract",
            url: "/financeiro/meus-contratos",
            title: "Meus Contratos",
            rule: "",
          },
          {
            index: "2.2",
            icon: "print",
            url: "/financeiro/extrato-financeiro",
            title: "Extrato Financeiro",
            rule: "",
          },
          {
            index: "2.3",
            icon: "archive",
            url: "/financeiro/documento-financeiro",
            title: "Documento Financeiro",
            rule: "",
          },
          /*{
                            index: "2.4",
                            icon: "file-signature",
                            url: "/financeiro/alterar-vencimento",
                            title: "Alterar Vencimento",
                            rule: "",
                        },*/
        ],
      },
      {
        header: "PROTOCOLOS",
        icon: "question-circle",
        rule: "isLogged",
        submenu: [
          {
            index: "3.1",
            icon: "folder-plus",
            url: "/protocolos/solicitacao",
            title: "Solicitação",
            rule: "",
          },
          {
            index: "3.2",
            icon: "list",
            url: "/protocolos/acompanhamento",
            title: "Acompanhamento",
            rule: "",
          },
        ],
      },
      /*{
                    header: "BIBLIOTECA",
                    icon: "book-reader",
                    rule: "isLogged",
                    submenu: [
                        {
                            index: "4.1",
                            icon: "folder",
                            url: "https://qifaculdade132663.rm.cloudtotvs.com.br/Corpore.Net/Main.aspx?ActionID=BibConsultaInternaActionWeb&SelectedMenuIDKey=ItemPesquisarReservar",
                            title: "Pesquisar/Reservar",
                            rule: "",
                            target: "_blank"
                        },
                        {
                            index: "4.2",
                            icon: "list",
                            url: "https://qifaculdade132663.rm.cloudtotvs.com.br/Corpore.Net/Main.aspx?ActionID=BibReservaActionWeb&SelectedMenuIDKey=ItemListarReserva",
                            title: "Listar Reservas",
                            rule: "",
                            target: "_blank"
                        },
                        {
                            index: "4.3",
                            icon: "book",
                            url: "https://plataforma.bvirtual.com.br/Account/Login?redirectUrl=%2F",
                            title: "Biblioteca Virtual",      
                            rule: "",
                            target: "_blank"
                        },
                    ]
                },*/
      {
        header: "FERRAMENTAS",
        icon: "folder-open",
        rule: "isLogged",
        submenu: [
          /*{
                            index: "5.1",
                            icon: "folder-open",
                            url: "/materiais/arquivos-da-instituicao",
                            title: "Arquivos da Instituição",
                            rule: "",
                        },*/
          {
            index: "5.2",
            icon: "newspaper",
            url: "/academico/acontecimentos",
            title: "Acontece aQI",
            rule: "",
          },
          {
            index: "5.3",
            icon: "mail-bulk",
            url: "https://qi.edu.br/blog/",
            title: "Blog QI",
            rule: "",
            target: "_blank",
          },
          {
            index: "5.4",
            icon: "rocket",
            url: "https://centrodepesquisa.qi.edu.br/",
            title: "Centro de Pesquisa",
            rule: "",
            target: "_blank",
          },
          {
            index: "5.5",
            icon: "wifi",
            url: "https://plataforma.bvirtual.com.br/Account/Login?redirectUrl=%2F",
            title: "Biblioteca Virtual",
            rule: "",
            target: "_blank",
          },
          {
            index: "5.6",
            icon: "book",
            url: "https://qifaculdade132663.rm.cloudtotvs.com.br/FrameHTML/web/app/Edu/PortalBiblioteca/#/?c=1&f=5&b=5",
            title: "Biblioteca Física",
            rule: "",
            target: "_blank",
          },
          {
            index: "5.7",
            icon: "network-wired",
            url: "https://vagas.qi.edu.br/",
            title: "Central de Vagas",
            rule: "",
            target: "_blank",
          },
          {
            index: "5.8",
            icon: "headset",
            url: "https://qi.edu.br/ouvidoria",
            title: "Ouvidoria",
            rule: "",
            target: "_blank",
          },
        ],
      },
    ];
  }
  getData() {
    return this.instance;
  }
}
