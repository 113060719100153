<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'archive']"
                />
                Manuais e Regulamentos
              </h3>
              <vs-divider />
              <vs-row class="pd-t">
                <vs-col>
                  <ul>
                    <li
                      class="button-contrast"
                      v-for="(doc, index) in docs"
                      :key="index"
                    >
                      <a href="#" @click="openLink(doc.link)">{{
                        doc.description
                      }}</a>
                    </li>
                  </ul>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocumentosAcademicos",
  data() {
    return {
      docs: [
        {
          description: "Manual do Aluno - Ensino Superior",
          link: "/files/ManualAluno.pdf",
        },
        {
          description: "Regulamento - Monitoria",
          link: "https://qi.edu.br/wp-content/uploads/2025/02/REGULAMENTO-PROGRAMA-DE-MONITORIA.docx.pdf",
        },
      ],
    };
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
<style scoped>
.button-contrast {
  margin-right: 1em;
  list-style-type: none;
  margin-bottom: 1em;
}
</style>