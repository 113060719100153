var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navbar'),_c('div',{staticClass:"columns"},[_c('SideBar',{staticClass:"menu"}),_c('section',{staticClass:"container"},[_c('Header',{staticClass:"header"}),_c('div',{staticClass:"card-sup"},[_c('vs-col',[_c('div',{staticClass:"card-info"},[_c('h3',[_c('font-awesome-icon',{staticStyle:{"margin-right":"3.5px"},attrs:{"icon":['fas', 'clock']}}),_vm._v(" Quadro de Horário ")],1),_c('vs-divider'),(_vm.subjects[0])?_c('div',[_c('vs-row',[_c('vs-col',[_c('vs-table',{attrs:{"data":_vm.subjects,"hoverFlat":""},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(i,index){return _c('vs-tr',{key:index},[_c('vs-td',[_c('fieldset',[_c('legend',{staticStyle:{"color":"black","width":"100%","text-align":"center","border":"1px solid","border-radius":"50px","padding":"5px"}},[_vm._v(" "+_vm._s(data[index].DISCIPLINA)+" ")]),_c('vs-col',[_c('vs-list',[_c('vs-list-item',{attrs:{"title":"CURSO","subtitle":data[index].CURSO}}),_c('vs-list-item',{attrs:{"title":"POLO / SALA","subtitle":data[index].NOMEPOLO == null
                                      ? '-'
                                      : data[index].SALA == null
                                      ? '-'
                                      : data[index].NOMEPOLO +
                                        ' / ' +
                                        data[index].SALA}}),_c('vs-list-item',{attrs:{"title":"PERÍODO","subtitle":data[index].DTINICIAL ==
                                    data[index].DTFINAL
                                      ? data[index].DTINICIAL
                                      : data[index].DTINICIAL +
                                        ' - ' +
                                        data[index].DTFINAL}})],1),(
                                  data[index].LINKAULA != '-' && (data[index].CODMODALIDADECURSO == '3' || data[index].CODMODALIDADECURSO == '5')
                                )?_c('fieldset',{staticStyle:{"margin-top":"10px"}},[_c('vs-list',[_c('div',{staticStyle:{"padding":"3.5px"}},[_c('b',[_vm._v("LINK DA AULA")]),_vm._v(" "),_c('br'),_c('a',{attrs:{"href":data[index].LINKAULA,"target":"_blank"}},[_vm._v(_vm._s(data[index].LINKAULA))])])])],1):_vm._e(),(
                                  data[index].SEGUNDA != '-' ||
                                  data[index].TERCA != '-' ||
                                  data[index].QUARTA != '-' ||
                                  data[index].QUINTA != '-' ||
                                  data[index].SEXTA != '-' ||
                                  data[index].SABADO != '-'
                                )?_c('fieldset',{staticStyle:{"margin-top":"10px"}},[_c('vs-list',[(data[index].SEGUNDA != '-')?_c('vs-list-item',{attrs:{"title":"SEGUNDA","subtitle":data[index].SEGUNDA}}):_vm._e(),(data[index].TERCA != '-')?_c('vs-list-item',{attrs:{"title":"TERÇA","subtitle":data[index].TERCA}}):_vm._e(),(data[index].QUARTA != '-')?_c('vs-list-item',{attrs:{"title":"QUARTA","subtitle":data[index].QUARTA}}):_vm._e(),(data[index].QUINTA != '-')?_c('vs-list-item',{attrs:{"title":"QUINTA","subtitle":data[index].QUINTA}}):_vm._e(),(data[index].SEXTA != '-')?_c('vs-list-item',{attrs:{"title":"SEXTA","subtitle":data[index].SEXTA}}):_vm._e(),(data[index].SABADO != '-')?_c('vs-list-item',{attrs:{"title":"SÁBADO","subtitle":data[index].SABADO}}):_vm._e()],1)],1):_vm._e()],1)],1)])],1)})}}],null,false,2381760764)})],1)],1)],1):_c('div',[_c('div',{staticClass:"error-info"},[_c('b',[_c('font-awesome-icon',{staticStyle:{"color":"red","margin-right":"3.5px"},attrs:{"icon":['fas', 'exclamation-triangle']}}),_vm._v(" Nenhuma disciplina encontrada ")],1)])])],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }