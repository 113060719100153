<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'archive']"
                />
                Documentos Acadêmicos
              </h3>
              <vs-divider />
              <vs-row class="pd-t">
                <vs-col>
                  <div class="alert alert-info">
                    <h3>
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'radiation']"
                      />
                      ATENÇÃO
                    </h3>
                    <p style="margin-top: 5px">
                      Somente alunos com matrícula ativa, podem emitir os
                      documentos acadêmicos.
                    </p>
                  </div>
                </vs-col>
              </vs-row>

              <vs-row class="pd-table">
                <vs-col>
                  <vs-table
                    class="table_intranet"
                    :data="academicDocuments"
                    hoverFlat
                  >
                    <template slot="thead">
                      <vs-th>DESCRIÇÃO</vs-th>
                      <vs-th width="6.15%">DOCUMENTO</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr v-for="(tr, index) in data" :key="index">
                        <vs-td>
                          <font-awesome-icon
                            class="icon"
                            :icon="data[index].icon"
                          />
                          {{ data[index].name }}
                        </vs-td>

                        <vs-td>
                          <vs-button class="button-table" @click="openPDF(tr)">
                            <font-awesome-icon
                              color="white"
                              style="margin-right: 3.5px"
                              :icon="['fas', 'download']"
                            />
                          </vs-button>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </vs-col>
              </vs-row>
              <vs-popup :title="nameDocument" :active.sync="popupAcademic">
                <ValidationObserver
                  ref="observerDocuments"
                  v-slot="{ invalid }"
                >
                  <vs-row>
                    <vs-col
                      v-if="
                        nameDocument == 'Atestado de Conclusão (Curso Técnico)'
                      "
                    >
                      <div v-if="coursesTechnician == ''">
                        <div class="error-info">
                          <b>
                            <font-awesome-icon
                              style="color: red; margin-right: 3.5px"
                              :icon="['fas', 'exclamation-triangle']"
                            />
                            Nenhum curso técnico encontrado
                          </b>
                        </div>
                      </div>
                      <div v-else>
                        <vs-col class="pd-t">
                          <fieldset>
                            <legend style="font-size: 1.15em">
                              Curso <b style="color: red">*</b>
                            </legend>
                            <ValidationProvider name="Curso" rules="required">
                              <vs-select
                                class="selectCourse"
                                placeholder="Selecione um Curso"
                                v-model="courseSelected"
                                @change="informationCourses()"
                              >
                                <vs-select-item
                                  :key="index"
                                  :value="item"
                                  :text="'(' + item.STATUS + ') - ' + item.NOME"
                                  v-for="(item, index) in coursesTechnician"
                                />
                              </vs-select>
                            </ValidationProvider>
                            <div
                              v-if="
                                !courseSelected ||
                                (courseSelected &&
                                  courseSelected.PENDENCIAFINANCEIRA == '0')
                              "
                              style="text-align: center"
                            >
                              <vs-button
                                type="gradient"
                                style="
                                  width: 97.85%;
                                  margin: 2.5% 1.075% 0 1.075%;
                                "
                                :disabled="invalid"
                                @click="getReportsTechnician()"
                              >
                                <font-awesome-icon
                                  style="margin-right: 3.5px"
                                  :icon="['fas', 'arrow-alt-circle-down']"
                                />
                                PDF
                              </vs-button>
                            </div>
                            <div
                              class="attendance note-info"
                              style="
                                margin-top: 13.5px;
                                margin-bottom: 0px !important;
                              "
                              v-if="
                                courseSelected &&
                                courseSelected.PENDENCIAFINANCEIRA != '0'
                              "
                            >
                              <h3>
                                <font-awesome-icon
                                  color="#9B0E1C"
                                  :icon="['fas', 'radiation']"
                                />
                                Ops! Algo deu errado...
                              </h3>
                              <br />
                              <p style="font-size: 1.185em">
                                Entre em contato com a Secretaria.<br /><br />
                                <b>
                                  <font-awesome-icon
                                    :icon="['fas', 'comments']"
                                  />
                                  WhatsApp:
                                  <a
                                    href="https://api.whatsapp.com/send?phone=5551995413830"
                                    target="_blank"
                                  >
                                    (51) 995413830
                                  </a>
                                </b>
                                <br />
                                <b>
                                  <font-awesome-icon :icon="['fas', 'phone']" />
                                  Telefone:
                                  <a href="tel:0800-0000-301" target="_blank">
                                    0800 000 0301
                                  </a>
                                </b>
                                <br /><br />
                                QI Faculdade & Escola Técnica<br />O mercado
                                reconhece, valoriza e contrata quem faz QI!
                              </p>
                            </div>
                          </fieldset>
                        </vs-col>
                      </div>
                    </vs-col>

                    <vs-col
                      v-if="
                        nameDocument == 'Atestado de Frequência' ||
                        nameDocument == 'Atestado de Matrícula'
                      "
                    >
                      <div v-if="courses == ''">
                        <div class="error-info">
                          <b>
                            <font-awesome-icon
                              style="color: red; margin-right: 3.5px"
                              :icon="['fas', 'exclamation-triangle']"
                            />
                            Nenhum curso encontrado
                          </b>
                        </div>
                      </div>
                      <div v-else>
                        <vs-col class="pd-t">
                          <fieldset>
                            <legend style="font-size: 1.15em">
                              Curso <b style="color: red">*</b>
                            </legend>
                            <ValidationProvider name="Curso" rules="required">
                              <vs-select
                                class="selectCourse"
                                placeholder="Selecione um Curso"
                                v-model="courseSelected"
                                @change="informationCourses()"
                              >
                                <vs-select-item
                                  :key="index"
                                  :value="item"
                                  :text="'(' + item.STATUS + ') - ' + item.NOME"
                                  v-for="(item, index) in courses"
                                />
                              </vs-select>
                            </ValidationProvider>
                            <div style="text-align: center">
                              <vs-button
                                type="gradient"
                                style="
                                  width: 97.85%;
                                  margin: 2.5% 1.075% 0 1.075%;
                                "
                                :disabled="invalid"
                                @click="generatePDF()"
                              >
                                <font-awesome-icon
                                  style="margin-right: 3.5px"
                                  :icon="['fas', 'arrow-alt-circle-down']"
                                />
                                PDF
                              </vs-button>
                            </div>
                          </fieldset>
                        </vs-col>
                      </div>
                    </vs-col>

                    <vs-col
                      v-if="nameDocument == 'Atestado de Prova (Graduação)'"
                    >
                      <div v-if="schedulings == ''">
                        <div class="error-info">
                          <b>
                            <font-awesome-icon
                              style="color: red; margin-right: 3.5px"
                              :icon="['fas', 'exclamation-triangle']"
                            />
                            Nenhuma disciplina encontrada
                          </b>
                        </div>
                      </div>

                      <div v-else>
                        <vs-col class="pd-t">
                          <fieldset>
                            <legend style="font-size: 1.15em">
                              Disciplina <b style="color: red">*</b>
                            </legend>
                            <ValidationProvider
                              name="Disciplina"
                              rules="required"
                            >
                              <vs-select
                                class="selectDiscipline"
                                placeholder="Selecione uma Disciplina"
                                v-model="disciplineSelected"
                              >
                                <vs-select-item
                                  :key="index"
                                  :value="item"
                                  :text="
                                    item.DISCIPLINA + ' (' + item.HORARIO + ')'
                                  "
                                  v-for="(item, index) in schedulings"
                                />
                              </vs-select>
                            </ValidationProvider>

                            <div style="text-align: center">
                              <vs-button
                                type="gradient"
                                style="
                                  width: 97.85%;
                                  margin: 2.5% 1.075% 0 1.075%;
                                "
                                :disabled="invalid"
                                @click="
                                  getReportsTest(disciplineSelected.IDATIVIDADE)
                                "
                              >
                                <font-awesome-icon
                                  style="margin-right: 3.5px"
                                  :icon="['fas', 'arrow-alt-circle-down']"
                                />
                                PDF
                              </vs-button>
                            </div>
                          </fieldset>
                        </vs-col>
                      </div>
                    </vs-col>

                    <vs-col
                      v-if="
                        nameDocument == 'Atestado de Coeficiente (Graduação)'
                      "
                    >
                      <div v-if="coursesCoefficient == ''">
                        <div class="error-info">
                          <b>
                            <font-awesome-icon
                              style="color: red; margin-right: 3.5px"
                              :icon="['fas', 'exclamation-triangle']"
                            />
                            Nenhum curso de graduação encontrado
                          </b>
                        </div>
                      </div>

                      <div v-else>
                        <vs-col class="pd-t">
                          <fieldset>
                            <legend style="font-size: 1.15em">
                              Curso <b style="color: red">*</b>
                            </legend>
                            <ValidationProvider name="Curso" rules="required">
                              <vs-select
                                class="selectCourse"
                                placeholder="Selecione um Curso"
                                v-model="courseSelected"
                                @change="informationCourses()"
                              >
                                <vs-select-item
                                  :key="index"
                                  :value="item"
                                  :text="'(' + item.STATUS + ') - ' + item.NOME"
                                  v-for="(item, index) in coursesCoefficient"
                                />
                              </vs-select>
                            </ValidationProvider>
                            <div style="text-align: center">
                              <vs-button
                                type="gradient"
                                style="
                                  width: 97.85%;
                                  margin: 2.5% 1.075% 0 1.075%;
                                "
                                :disabled="invalid"
                                @click="getReportsCoefficient()"
                              >
                                <font-awesome-icon
                                  style="margin-right: 3.5px"
                                  :icon="['fas', 'arrow-alt-circle-down']"
                                />
                                PDF
                              </vs-button>
                            </div>
                          </fieldset>
                        </vs-col>
                      </div>
                    </vs-col>

                    <vs-col
                      v-if="
                        nameDocument == 'Declaração de Conclusão (Graduação)'
                      "
                    >
                      <div v-if="coursesGraduationCollation == ''">
                        <div class="error-info">
                          <b>
                            <font-awesome-icon
                              style="color: red; margin-right: 3.5px"
                              :icon="['fas', 'exclamation-triangle']"
                            />
                            Nenhum curso de graduação encontrado
                          </b>
                        </div>
                      </div>

                      <div v-else>
                        <vs-col class="pd-t">
                          <fieldset>
                            <legend style="font-size: 1.15em">
                              Curso <b style="color: red">*</b>
                            </legend>
                            <ValidationProvider name="Curso" rules="required">
                              <vs-select
                                class="selectCourse"
                                placeholder="Selecione um Curso"
                                v-model="courseSelected"
                                @change="informationCourses()"
                              >
                                <vs-select-item
                                  :key="index"
                                  :value="item"
                                  :text="'(' + item.STATUS + ') - ' + item.NOME"
                                  v-for="(
                                    item, index
                                  ) in coursesGraduationCollation"
                                />
                              </vs-select>
                            </ValidationProvider>
                            <div style="text-align: center">
                              <vs-button
                                type="gradient"
                                style="
                                  width: 97.85%;
                                  margin: 2.5% 1.075% 0 1.075%;
                                "
                                :disabled="invalid"
                                @click="getReportsGraduation()"
                              >
                                <font-awesome-icon
                                  style="margin-right: 3.5px"
                                  :icon="['fas', 'arrow-alt-circle-down']"
                                />
                                PDF
                              </vs-button>
                            </div>
                          </fieldset>
                        </vs-col>
                      </div>
                    </vs-col>

                    <vs-col v-if="nameDocument == 'Histórico'">
                      <div v-if="coursesHistoric == ''">
                        <div class="error-info">
                          <b>
                            <font-awesome-icon
                              style="color: red; margin-right: 3.5px"
                              :icon="['fas', 'exclamation-triangle']"
                            />
                            Nenhum curso encontrado
                          </b>
                        </div>
                      </div>
                      <div v-else>
                        <vs-col class="pd-t">
                          <fieldset>
                            <legend style="font-size: 1.15em">
                              Curso <b style="color: red">*</b>
                            </legend>
                            <ValidationProvider name="Curso" rules="required">
                              <vs-select
                                class="selectCourse"
                                placeholder="Selecione um Curso"
                                v-model="courseSelected"
                                @change="informationCourses()"
                              >
                                <vs-select-item
                                  :key="index"
                                  :value="item"
                                  :text="'(' + item.STATUS + ') - ' + item.NOME"
                                  v-for="(item, index) in coursesHistoric"
                                />
                              </vs-select>
                            </ValidationProvider>
                            <div style="text-align: center">
                              <vs-button
                                type="gradient"
                                style="
                                  width: 97.85%;
                                  margin: 2.5% 1.075% 0 1.075%;
                                "
                                :disabled="invalid"
                                @click="getReportsHistoric()"
                              >
                                <font-awesome-icon
                                  style="margin-right: 3.5px"
                                  :icon="['fas', 'arrow-alt-circle-down']"
                                />
                                PDF
                              </vs-button>
                            </div>
                          </fieldset>
                        </vs-col>
                      </div>
                    </vs-col>
                  </vs-row>

                  <vs-row v-if="showDownloadModal">
                    <div class="sucess sucess-info" style="width: 100%">
                      <h3>
                        <font-awesome-icon
                          color="#139B0E"
                          style="margin-right: 2.5px"
                          :icon="['fas', 'check-circle']"
                        />
                        Documento gerado com sucesso
                      </h3>
                      <br />
                      <p style="font-size: 1.185em; color: black">
                        Prezado aluno, seu documento foi gerado com sucesso,<br /><br />
                        <a
                          style="color: black"
                          :href="urlDocument"
                          target="_blank"
                        >
                          Clique aqui
                        </a>
                        para abrir o documento.
                        <br /><br />
                        QI Faculdade & Escola Técnica<br />O mercado reconhece,
                        valoriza e contrata quem faz QI!
                      </p>
                    </div>
                  </vs-row>
                </ValidationObserver>
              </vs-popup>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Documents from "../../services/documents";
import Person from "../../services/person";
import Courses from "../../services/management";

export default {
  name: "DocumentosAcademicos",
  data: () => ({
    schedulings: [],
    personalData: [],
    courseSelected: null,
    disciplineSelected: null,
    showDownloadModal: false,
    urlDocument: null,
    certificate: [],
    coefficient: [],
    graduation: [],
    technician: [],
    frequency: [],
    historic: [],
    popupAcademic: false,
    academicDocuments: [
      { name: "Atestado de Conclusão (Curso Técnico)", icon: "check-double" },
      { name: "Atestado de Frequência", icon: "thumbs-up" },
      { name: "Atestado de Matrícula", icon: "flag" },
      { name: "Atestado de Prova (Graduação)", icon: "pen" },
      { name: "Atestado de Coeficiente (Graduação)", icon: "thumbs-up" },
      { name: "Declaração de Conclusão (Graduação)", icon: "check-double" },
      { name: "Histórico", icon: "calendar-alt" },
    ],
    nameDocument: null,
    idTypeCourse: null,
    idCourse: null,
    modality: null,
    reportId: null,
    idHabilitationFilial: null,
    idActivity: null,
    ra: null,
    data: null,
    coursesTechnician: [],
    courses: [],
    coursesTest: [],
    test: null,
    replacement: null,
    coursesCoefficient: [],
    coursesGraduationCollation: [],
    coursesHistoric: [],
    discipline: [],
  }),

  mounted() {
    this.$vs.loading({ text: "Buscando Documentos 📚" });
    new Courses()
      .getCoursesForTechnician()
      .then(({ data }) => {
        this.coursesTechnician = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new Courses()
      .getCoursesForReport()
      .then(({ data }) => {
        this.courses = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.ra = this.personalData[0].RA;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new Courses()
      .getCoursesForTest()
      .then(({ data }) => {
        this.schedulings = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new Courses()
      .getCoursesForCoefficient()
      .then(({ data }) => {
        this.coursesCoefficient = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new Courses()
      .getGraduationCollation()
      .then(({ data }) => {
        this.coursesGraduationCollation = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      });

    new Courses()
      .getCoursesForHistoric()
      .then(({ data }) => {
        this.coursesHistoric = Array.isArray(data) ? data : [data];
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    informationCourses() {
      this.idCourse = this.courseSelected.CODCURSO;
      this.idTypeCourse = this.courseSelected.CODTIPOCURSO;
      this.modality = this.courseSelected.CODMODALIDADECURSO;
      this.idHabilitationFilial = this.courseSelected.IDHABILITACAOFILIAL;
    },

    openPDF(tr) {
      this.popupAcademic = true;
      this.showDownloadModal = false;
      this.nameDocument = tr.name;
      this.resetForm();
    },

    openBase64InNewTab(base64Data) {
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      this.urlDocument = URL.createObjectURL(blob);
      this.showDownloadModal = true;
    },

    getReportsTechnician() {
      this.showDownloadModal = false;
      this.informationCourses();

      if (this.idTypeCourse == "3") {
        this.reportId = 511;
      } else {
        this.reportId = 0;
      }

      let tableName = "SHABILITACAOALUNO";
      let filterTag =
        "SHABILITACAOALUNO.CODCOLIGADA=1 AND SHABILITACAOALUNO.RA= '" +
        this.ra +
        "' AND SHABILITACAOALUNO.IDHABILITACAOFILIAL=" +
        this.idHabilitationFilial;
      let filename = "ATESTADO-DE-CONCLUSAO-" + this.ra + ".pdf";

      const payloadGraduation = {
        colligateId: 1,
        reportId: this.reportId,
        filename: filename,
        tableName: tableName,
        filterTag: filterTag,
      };

      this.$vs.loading({ text: "Gerando Atestado de Conclusão 📩" });
      new Documents()
        .getReports(payloadGraduation)
        .then(({ data }) => {
          this.technician = Array.isArray(data) ? data : [data];
          this.openBase64InNewTab(this.technician);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },

    getReportsFrequency() {
      this.showDownloadModal = false;
      this.informationCourses();

      if (this.idCourse == "002" || this.idCourse == "003") {
        this.reportId = 565;
      } else {
        this.reportId = 537;
      }

      let tableName = "SHABILITACAOALUNO";
      let filterTag =
        "SHABILITACAOALUNO.CODCOLIGADA=1 AND SHABILITACAOALUNO.RA= '" +
        this.ra +
        "' AND SHABILITACAOALUNO.IDHABILITACAOFILIAL=" +
        this.idHabilitationFilial;
      let filename = "ATESTADO-DE-FREQUENCIA-" + this.ra + ".pdf";

      const payloadFrequency = {
        colligateId: 1,
        reportId: this.reportId,
        filename: filename,
        tableName: tableName,
        filterTag: filterTag,
      };

      this.$vs.loading({ text: "Gerando Atestado de Frequência 📩" });
      new Documents()
        .getReports(payloadFrequency)
        .then(({ data }) => {
          this.frequency = Array.isArray(data) ? data : [data];
          this.openBase64InNewTab(this.frequency);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },

    getReportsMatriculation() {
      this.showDownloadModal = false;
      this.informationCourses();

      if (this.idTypeCourse == "2") {
        this.reportId = 495;
      } else if (this.idCourse == "002" || this.idCourse == "003") {
        this.reportId = 561;
      } else if (this.idTypeCourse == "3" && this.modality == "1") {
        this.reportId = 437;
      } else if (
        this.modality == "2" ||
        this.modality == "4" ||
        this.modality == "6"
      ) {
        this.reportId = 616;
      }

      let tableName = "SHABILITACAOALUNO";
      let filterTag =
        "SHABILITACAOALUNO.CODCOLIGADA=1 AND SHABILITACAOALUNO.RA= '" +
        this.ra +
        "' AND SHABILITACAOALUNO.IDHABILITACAOFILIAL=" +
        this.idHabilitationFilial;
      let filename = "ATESTADO-DE-MATRICULA-" + this.ra + ".pdf";

      const payloadMatriculation = {
        colligateId: 1,
        reportId: this.reportId,
        filename: filename,
        tableName: tableName,
        filterTag: filterTag,
      };

      this.$vs.loading({ text: "Gerando Atestado de Matrícula 📩" });
      new Documents()
        .getReports(payloadMatriculation)
        .then(({ data }) => {
          this.certificate = Array.isArray(data) ? data : [data];
          this.openBase64InNewTab(this.certificate);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },

    getReportsTest(idAtividade) {
      this.showDownloadModal = false;

      let tableName = "SATIVIDADEALUNO";
      let filterTag =
        "SATIVIDADEALUNO.CODCOLIGADA=1 AND SATIVIDADEALUNO.RA= '" +
        this.ra +
        "' AND SATIVIDADEALUNO.IDATIVIDADE=" +
        idAtividade;
      let filename = "ATESTADO-DE-PROVA-" + this.ra + ".pdf";

      const payloadTest = {
        colligateId: 1,
        reportId: 620,
        filename: filename,
        tableName: tableName,
        filterTag: filterTag,
      };

      this.$vs.loading({ text: "Gerando Atestado de Prova 📩" });
      new Documents()
        .getReports(payloadTest)
        .then(({ data }) => {
          this.test = Array.isArray(data) ? data : [data];
          this.openBase64InNewTab(this.test);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },

    getReportsCoefficient() {
      this.showDownloadModal = false;
      this.informationCourses();

      let tableName = "SHABILITACAOALUNO";
      let filterTag =
        "SHABILITACAOALUNO.CODCOLIGADA=1 AND SHABILITACAOALUNO.RA= '" +
        this.ra +
        "' AND SHABILITACAOALUNO.IDHABILITACAOFILIAL=" +
        this.idHabilitationFilial;
      let filename = "ATESTADO-DE-COEFICIENTE-" + this.ra + ".pdf";

      const payloadCoefficient = {
        colligateId: 1,
        reportId: 775,
        filename: filename,
        tableName: tableName,
        filterTag: filterTag,
      };

      this.$vs.loading({ text: "Gerando Atestado de Coeficiente 📩" });
      new Documents()
        .getReports(payloadCoefficient)
        .then(({ data }) => {
          this.coefficient = Array.isArray(data) ? data : [data];
          this.openBase64InNewTab(this.coefficient);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },

    getReportsGraduation() {
      this.showDownloadModal = false;
      this.informationCourses();

      if (this.idTypeCourse == "2") {
        this.reportId = 609;
      } else {
        this.reportId = 0;
      }

      let tableName = "SHABILITACAOALUNO";
      let filterTag =
        "SHABILITACAOALUNO.CODCOLIGADA=1 AND SHABILITACAOALUNO.RA= '" +
        this.ra +
        "' AND SHABILITACAOALUNO.IDHABILITACAOFILIAL=" +
        this.idHabilitationFilial;
      let filename = "DECLARACAO-DE-CONCLUSAO-" + this.ra + ".pdf";

      const payloadGraduation = {
        colligateId: 1,
        reportId: this.reportId,
        filename: filename,
        tableName: tableName,
        filterTag: filterTag,
      };

      this.$vs.loading({ text: "Gerando Declaração de Conclusão 📩" });
      new Documents()
        .getReports(payloadGraduation)
        .then(({ data }) => {
          this.graduation = Array.isArray(data) ? data : [data];
          this.openBase64InNewTab(this.graduation);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },

    getReportsHistoric() {
      this.showDownloadModal = false;
      this.informationCourses();

      if (this.idTypeCourse == "2") {
        this.reportId = 782;
      } else if (this.idCourse == "002" || this.idCourse == "003") {
        this.reportId = 410;
      } else if (this.idTypeCourse == "3" && this.modality == "1") {
        this.reportId = 410;
      } else if (this.modality == "4") {
        this.reportId = 410;
      }

      let tableName = "SHABILITACAOALUNO";
      let filterTag =
        "SHABILITACAOALUNO.CODCOLIGADA=1 AND SHABILITACAOALUNO.RA= '" +
        this.ra +
        "' AND SHABILITACAOALUNO.IDHABILITACAOFILIAL=" +
        this.idHabilitationFilial;
      let filename = "HISTORICO-" + this.ra + ".pdf";

      const payloadHistoric = {
        colligateId: 1,
        reportId: this.reportId,
        filename: filename,
        tableName: tableName,
        filterTag: filterTag,
      };

      this.$vs.loading({ text: "Gerando Histórico 📩" });
      new Documents()
        .getReports(payloadHistoric)
        .then(({ data }) => {
          this.historic = Array.isArray(data) ? data : [data];
          this.openBase64InNewTab(this.historic);
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
            console.log(response);
          }
        })
        .finally(() => {
          this.$vs.loading.close();
          this.resetForm();
        });
    },

    generatePDF() {
      if (this.nameDocument == "Atestado de Frequência") {
        this.getReportsFrequency();
      } else {
        this.getReportsMatriculation();
      }

      this.resetForm();
    },

    resetForm() {
      this.courseSelected = "";
      this.disciplineSelected = null;
      this.test = null;
      this.replacement = null;

      this.$nextTick(() => {
        this.$refs.observerDocuments.reset();
      });
    },
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #d4d4d4;
}

.icon {
  font-size: 1.35em;
  margin-right: 5px;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}

.selectCourse,
.selectDiscipline {
  margin: auto;
  width: 98.5%;
}
</style>